import React from "react"

import LayoutComponent from "../components/layout.component"

const NotFoundPage = () => (
  <LayoutComponent>
    <div className="container">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </LayoutComponent>
)

export default NotFoundPage
